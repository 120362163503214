import { AppEnv, EnvProps } from "./env-types";
import { envDefaults } from "./env-defaults";
import { merge } from "lodash";

const env: EnvProps = merge(envDefaults, {
  appEnv: AppEnv.PROD,
  oidc: {
    clientId: "0oalrgbwsu4f6OFgT0x7",
    issuer: "https://mlb.okta.com/oauth2/auslv3cyxpTq2DdSK0x7",
  },
  baseURL: "https://lineupcards-api.mlbinfra.com",
});

window.MLBBest = { env };
